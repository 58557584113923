<template>
   <div style="margin: -15px -15px;background-color: #f0f2f5;">
    <!-- tag查询框 -->
    <div class="datePicker">
      <div style="display: flex;align-items: center;">
        <div class="liveMeet-top-input" >
          <span style="margin-right: 20px;">年份:</span>
          <!-- :open="yearShowOne" -->
          <a-date-picker
              v-model="year"
              mode="year"
              v-decorator="['year']"
              placeholder="请输入"
              format="YYYY"
              class="input"
              @openChange="openChangeOne"
              @panelChange="panelChangeOne"
              style="width:50%;"
            />
        </div>
        <div class="date">
          <div style="width: 46px;">名称：</div>
        <!-- @keyup.enter="searchBtn" v-model="searchName"  -->
        <a-input style="width: 180px"  placeholder="请输入" v-model="searchName"></a-input>
        </div>
        <!-- @click="searchBtn" -->
        <a-button type="primary" style="margin-left: 30px;" @click="searchBtn">查询</a-button>
        <!-- @click="returnBtn" -->
        <a-button style="margin-left: 15px" @click="returnBtn">重置</a-button>
     </div>
      <div style="display: flex;align-items:center;margin-right: 50px;">
        <span>状态</span>: &nbsp;
        <!-- @change="searchOk" -->
        <a-radio-group  v-model="Ftype" @change="searchOk">
          <a-radio-button value="">
            全部
          </a-radio-button>
          <a-radio-button value="1">
            开启
          </a-radio-button>
          <a-radio-button value="0">
            关闭
          </a-radio-button>
        </a-radio-group>
      </div>
    </div>
    <!-- table框 -->
    <div style="background-color: #fff;padding: 20px; padding-bottom: 80px;">
        <a-button type="primary" style="margin-bottom: 20px;margin-right:3%;" @click="newlyBuilt">
          新增
        </a-button>
        <a-table :columns="columns" style="min-height: 500px;background-color: #fff;" :data-source="officalServeyTable" :rowKey="record => record.id" :scroll="{ x: 1500}"  :pagination="false"
         >
         <!-- 图片 -->
         <span slot="icon" slot-scope="text,item">
           <img :src="item.image" style="height: 70px;width: 180px;" alt="">
         </span>
         <!-- 内容详情悬浮框 -->
         <span slot="details" slot-scope="text,item">
            <!-- 悬浮框 -->
            <a-tooltip placement="topLeft" >
              <template slot="title" v-html="item.content">
                {{ item.content }}
              </template>
              <span class="multi-ellipsis"  v-html="item.content">{{ item.content }}</span>
            </a-tooltip>
          </span>
         <!-- 操作栏 -->
         <span slot="operate" slot-scope="text,record,index">
           <!-- <a @click="upBtn(record)" @click="downBtn(record)">上移</a> -->
           <a :disabled="index + ((page_no - 1) * pageSize) + 1 == 1" @click="upBtn(record)" >上移</a>
           <a style="margin-left: 10px"  :disabled="index + ((page_no - 1) * pageSize) + 1 == total" @click="downBtn(record)">下移</a>
           <a style="margin-left: 10px" @click="lookBtn(record)">修改</a>
            <a-popconfirm
                style="margin-left: 10px"
                title="是否确认删除?"
                ok-text="是"
                cancel-text="否"
                @confirm="delBtn(record.id)"
            >
            <!--  -->
              <a href="#">删除</a>
            </a-popconfirm>
         </span>
         <!-- 状态 -->
         <span slot="changeJia" slot-scope="text, record,item,id">
            <a-switch
                @change="onChange(record.id,record.state === 1?0:1,record)"
                :checked="record.state!==0"
                checked-children="是"
                un-checked-children="否"
                default-checked />
          </span>
        </a-table>
    </div>
    <!-- table的分页 -->
    <div style="margin-top: 28px;display:flex;align-items:center;justify-content:space-between;">
      <span
        style="font-size: medium;color: #929292;margin-left: 3%;font-size: 14px;  bottom: 3%;">
        共{{ total }}条记录 第 {{ page_no }} / {{ Math.ceil(total /
          pageSize)
        }} 页
      </span>
      <a-pagination style="float: right;" show-quick-jumper show-size-changer :page-size.sync="pageSize"
        :total=total v-model="page_no"  @change="pageChange" @showSizeChange="pageChange"
       />
    </div>
    <!-- 新建/编辑框  -->
    <a-drawer title="新增/修改" placement="right" :closable="false" :visible="newlyVisible"
              width="700px" @close="onClose1">
      <template>
        <a-form-model
            :model="addform"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            :rules="rules"
            ref="addform">
          <a-form-model-item label="封面图" prop="image" v-model="addform.image">
            <div>
              <div style="display: flex;">
                <span class="uploadImg">
                  <input
                      id="inputTest"
                      ref="addImg"
                      accept="image/*"
                      style="width: 100%;height:100%;opacity: 0;margin-right: 14px;"
                      type="file"
                      @change="uploadImg('addImg')"
                      />
                </span>
                <span class="upload_gutter" v-if="addform.image !=='' " >
                  <img
                    :src="addform.image"
                    alt=""
                    style="width: 200px;height: 100px;"
                  />
                </span>
                <!-- <span id="yulan">
                  <span class="upload_gutter" v-for="(items,index) in addform.image" >
                    <div style="height: 120px;width: 120px;position: relative;margin-right: 10px;border: #DDDDDD 2px dashed;">
                      <a-icon type="close" style="color: red;position: absolute;right: 0;" @click="getIndex(index)"/>
                      <img :index="index"
                           :src="items"
                           alt=""
                           style="width: 120px;height: 120px;"
                           @click="openVideoUrl(items)"
                      />
                    </div>
                  </span>
                </span> -->
              </div>
            </div>
          </a-form-model-item>
          <a-form-model-item label="标题" prop="title">
            <a-input v-model="addform.title" placeholder="请输入" />
          </a-form-model-item>
          <a-form-model-item  label="跳转链接" prop="link" >
            <a-input v-model="addform.link" auto-size placeholder="请输入" style="font-size: 16px"/>
          </a-form-model-item>
          
         <a-form-model-item  label="是否需要登录" prop="isLogin">
          <!-- onNewBuildChange(record.id,record.state === 1?0:1,record)  :checked="record.state!==0" -->
           <a-switch
                 @change="onNewBuildChange(addform.isLogin === 1? 0 : 1)"
                 v-model="addform.isLogin === 1?true:false" 
                 checked-children="是"
                 un-checked-children="否"
                 default-checked
                  />
         </a-form-model-item>

         <!-- <a-switch
              style="margin-right: 5px"
              v-model="videoForm.conferenceVideoSwitch === 1?true:false"
              checked-children="是"
              un-checked-children="否"
              @click="conferenceVideoSwitchChange(videoForm.conferenceVideoSwitch === 1? 0 : 1)"
              default-checked /> -->

          <a-form-model-item prop="content" label="内容详情">
            <div style="border: 1px solid #ccc;min-height: 300px;width: 500px">
              <Toolbar
                  style="border-bottom: 1px solid #ccc"
                  :editor="editor"
                  :defaultConfig="toolbarConfig"
                  :mode="mode"
              />
              <Editor
                  style="overflow-y: hidden;"
                  v-model="html"
                  :defaultConfig="editorConfig"
                  :mode="mode"
                  @onCreated="onCreated"
              />
            </div>
          </a-form-model-item>

          <a-form-model-item label="备注" prop="remark" style="margin-bottom: 100px;">
            <a-input v-model="addform.remark" placeholder="请输入" />
          </a-form-model-item>
        </a-form-model>
         <div
           :style="{
             position: 'absolute',
             right: 0,
             bottom: 0,
             width: '100%',
             borderTop: '1px solid #e9e9e9',
             padding: '10px 16px',
             background: '#fff',
             textAlign: 'right',
             zIndex: 1,
           }"
         >
           <a-button :style="{ marginRight: '8px' }" @click="()=>{
             this.newlyVisible = false;
             this.$refs.addform.resetFields()
           }">
             取消
           </a-button>
           <a-button :style="{ marginRight: '8px' }" type="primary" @click="submit" v-if="this.btnStatus == '新建'">
             确认
           </a-button>
           <a-button :style="{ marginRight: '8px' }" type="primary" @click="editSubmit" v-if="this.btnStatus == '修改'">
             确认
           </a-button>
         </div>
      </template>
    </a-drawer>
   </div>
</template>

<script>
import moment from "moment";
import {update} from "@/utils/update";
import {manageListDetail, myManageList} from "@/service/MedicalConference_y";
import {current_date} from "@/utils/currentDate";
import {getOfficalSurvey,putOfficalSurvey,putUpSurvey,putdownSurvey,postSurvey,delSurvey,getSurveyDetails,
  putSurvey
} from "@/service/Help";
import axios from "axios";
import TcVod from "vod-js-sdk-v6";
import {Editor, Toolbar} from "@wangeditor/editor-for-vue";
const editorConfig = { // JS 语法
  MENU_CONF: {}
};
const signature = '';
// 修改 uploadImage 菜单配置
editorConfig.MENU_CONF['uploadImage'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let formData = new FormData()
    formData.append('file', file)
    // file 即选中的文件,自己实现上传，并得到图片 url alt href
    axios.post('https://yun-new-admin-api.yunicu.com/api/admin/common/update', formData).then((res) => {
      if(res.data.code === 0){
        insertFn(res.data.data.url)// 最后插入图片
      }
    })
  }
}
// 修改 uploadVideo 菜单配置
editorConfig.MENU_CONF['uploadVideo'] = {
  // 自定义上传
  async customUpload(file, insertFn) {
    let mediaFile = file;
    //获取签名
    axios.get('https://yun-new-admin-api.yunicu.com/v3/getUgcUploadSign').then((res) => {
      if (res.data.code === 0) {
        const getSignature = () => {
          return res.data.data.signature;
        }
        const tcVod = new TcVod({
          getSignature: getSignature //上传签名的函数
        })
        const uploader = tcVod.upload({
          mediaFile: mediaFile, // 媒体文件（视频或音频或图片），类型为 File
        })
        uploader.done().then(res => {
          insertFn(res.video.url)// 进行视频处理：转码和截图
        })
      }
    })
  }
}
export default {
  components:{ Editor, Toolbar},
  data() {
    return{
      year: moment(current_date()).format('YYYY'),
      columns:[
        {
          title: '封面图',
          dataIndex: 'image',
          key: 'image',
          // align:'center',
          // width:'8%',
          scopedSlots: { customRender: 'icon' }
        },
        {
          title: '标题',
          dataIndex: 'title',
          width:'20%',
          key: 'title',
          // scopedSlots: { customRender: 'changeJia' }
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
        },
        {
          title: '状态',
          dataIndex: 'state',
          key: 'state',
          scopedSlots: { customRender: 'changeJia' }
        },
        {
          title: '内容详情',
          dataIndex: 'content',
          key: 'content',
          scopedSlots: { customRender: 'details' }
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          // scopedSlots: { customRender: 'changeJia' }
        },
        {
          title: '操作',
          // width: '20%',
          fixed: 'right',
          scopedSlots: { customRender: 'operate' },
        },
      ],
      Ftype:"",
      officalServeyTable:[],
      page_no:1,
      pageSize:10,
      total:0,
      searchName:"",
      newlyVisible: false,
      newlyEditVisible:false,
      addform: {  //表单的内容
        // content: '',
        // remark:'',
        // module: undefined,
        // channel: undefined,
        // degree: undefined,
        image: "",
        isLogin:0,
        // videos: [{ videoo: null }], //视频文件
        // fileId: this.yunVideo_fileId,  //
      },
      labelCol: { span: 4},
      wrapperCol: { span: 18 },
      officlaState:"",
      rules: {
        // content: [{ required: true, message: '请输入描述', trigger: 'blur' }],
        // degree: [{ required: true, message: '请选择紧急程度', trigger: 'blur' }],
        link: [{ required: true, message: '请输入', trigger: 'blur' }],
        title: [{ required: true, message: '请输入', trigger: 'blur' }],
        image: [{ required: true, message: '请上传图片', trigger: 'blur' }],
        isLogin: [{required: true, message: '请选择', trigger: 'change'}],
      },
      btnStatus:"",
      editor: null,
      html: '',
      toolbarConfig: { },
      editorConfig,
      mode: 'default', // or 'simple'
    }

  },
  async mounted() {
    // await this.getList()
    await this.getOfficaWebSurvey()
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 弹出日历和关闭日历的回调
    openChangeOne(status) {
      // if (status) {
      //   this.yearShowOne = true;
      // }else {
      //   this.yearShowOne = false;
      // }
    },
    // 得到年份选择器的值
    panelChangeOne(value) {
      // this.yearShowOne = false;
      this.year = value;
      this.page_no = 1
      if(this.year){
        this.year = moment(this.year).format('YYYY')
      }
      console.log(this.year)
      //  刷新
      this.getOfficaWebSurvey()
    },
    // 新建表单
    newlyBuilt() {
      this.html = '';
      this.onCreated();
      this.newlyVisible = true;
      this.btnStatus = "新建"
      // this.getIndex(this.index)
      // this.getVideoIndex(this.VideoIndex)
      // this.buttonType = true
      // this.startTime = (moment().format('YYYY-MM-DD'))
      // console.log("看看是不是当前时间",startTime)
    },
    // *****table数据*****
    async getOfficaWebSurvey(){
      console.log(this.year)
      const response = await getOfficalSurvey(this.year,this.searchName,this.page_no,this.pageSize,this.officlaState)
      console.log("请求",response)
      if (response.code === 0) {
        console.log("请求",response)
        this.officalServeyTable = response.data.list
        this.total = response.data.total
      }else{
        this.$message.warning(response.message)
      }
    },
    // 状态切换
    async onChange(id, open) {
      // console.log("看看id和状态",id, open,record)
      const Info = await putOfficalSurvey(id,open)
      if (Info.code === 0){
        this.getOfficaWebSurvey()
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败' + Info.message)
      }
   },
    // 新建框状态切换
    async onNewBuildChange( checked) {
      // console.log("看看id和状态", checked)
      console.log(`a-switch to ${checked}`);
      this.addform.isLogin = checked
      // const Info = await putOfficalSurvey(id,open)
      // if (Info.code === 0){
      //   this.getOfficaWebSurvey()
      //   this.$message.success('操作成功')
      // }else {
      //   this.$message.error('操作失败' + Info.message)
      // }
   },

    // 上移
    async upBtn(row){
      const response = await putUpSurvey(row.id)
      if (response.code === 0) {
        this.$message.success('操作成功')
        this.getOfficaWebSurvey()
      }else{
        this.$message.warning(response.message)
      }
    },
    // 下移  downBtn
    async downBtn(row){
      const response = await putdownSurvey(row.id)
      if (response.code === 0) {
        this.$message.success('操作成功')
        this.getOfficaWebSurvey()
      }else{
        this.$message.warning(response.message)
      }
    },
    // 搜索框查询
    searchBtn() {
      // 点击查询的时候不要管页码的参数
      // this.pagination.pageNum = 1;
      // this.pagination.pageSize = 10;
      // this.pagination.current = 1
      // console.log(this.searchName)
      this.page_no = 1
      this.getOfficaWebSurvey()
    },
    // 分页传参
    pageChange(page_no, pageSize) {
      // console.log(page_size_options)
      this.page_no = page_no
      this.pageSize = pageSize
      console.log(page_no)
      console.log(pageSize)
      this.getOfficaWebSurvey()
    },
    // 状态框查询
    searchOk(e) {
      switch (e.target.value) {
        // 全部
        case '':
          // this.getFeedback(1,'METTING',this.searchType)
          this.officlaState = ""
          this.pageSize = 10
          this.page_no = 1
          this.getOfficaWebSurvey()
          break;
        // 缺陷
        case '1':
          // this.getFeedback(1,'METTING',this.searchType)
          this.officlaState = 1
          this.pageSize = 10
          this.page_no = 1
          this.getOfficaWebSurvey()
          break;
        // 需求
        case '0':
          // this.getLogData(1,'CONAULTATION',this.searchType)
          this.officlaState = 0
          this.pageSize = 10
          this.page_no = 1
          this.getOfficaWebSurvey()
          break;
      }
    },
    //上传图片
    async uploadImg(type) {
      var inputTest = document.querySelector('#inputTest')
      let inputDOM = this.$refs[type].files[0];
      if(inputDOM.size < 512000 ){
        const image = await update(inputDOM)
        // if (this.form.image.length > 9) {
        //   this.$message.warning('太多了')
        // }
        if (image.code === 0 ){
          switch (type){
            case 'addImg':
            this.addform.image = image.data.url
              // this.addform.image.push(image.data.url)
              // console.log(image.data.url)
              // console.log(this.addform)
              break;
          }
          this.$message.success('上传成功')
        }
        else {
          this.$message.warning('最多只能上传9张图片')
        }
      }

      // if(inputDOM.size < 512000 ){
      //   const image = await update(inputDOM)
      //   // if (this.form.image.length > 9) {
      //   //   this.$message.warning('太多了')
      //   // }
      //   if (image.code === 0 && this.addform.image.length < 9 ){
      //     switch (type){
      //       case 'addImg':
      //         this.addform.image.push(image.data.url)
      //         console.log(image.data.url)
      //         // console.log(this.addform)
      //         break;
      //     }
      //     this.$message.success('上传成功')
      //   }
      //   else {
      //     this.$message.warning('最多只能上传9张图片')
      //   }
      // }
      else{
        this.$message.warning('仅上传500k内图片')
      }
      inputTest.value =''
    },

    // 关弹窗
    // 新建抽屉关闭
    onClose1() {
      this.newlyVisible = false;
      // this.disabledVideo = false
      // // let form = this.form
      // this.form.content = ''
      // this.form.remark = ''
      // this.form.module = undefined
      // this.form.channel = undefined
      // this.form.degree = undefined
      // this.form.image = [],
      //   this.form.videos = []
      // // 清空表单验证
      this.$refs.addform.resetFields()
      // console.log("关闭时候的form",this.form)
    },
    // 新建后的提交按钮
    async submit() {
      this.addform.content = this.html;
      this.$refs.addform.validate(async valid => { // 表单校验
        if (valid) {
          console.log(this.addform)
          // // 1.获取表单内容
          const form = this.addform
          // const user_info = window.localStorage.getItem("user_info");
          const addData = {
            // memberId:JSON.parse(user_info).id,
            "content": form.content,     //备注
            "image": form.image,   //描述
            "link": form.link,    //紧急
            "isLogin": form.isLogin,
            "title": form.title,
            "remark": form.remark
          }
          // // console.log("有没有",addData)  addform.isLogin
          // const response = await postSurvey(this.addform)  addData
          const response = await postSurvey(addData)
          if (response.code === 0) {
            this.$message.success('新增成功')
            this.newlyVisible = false;
            this.getOfficaWebSurvey()
            // 重置表单
            this.$refs.addform.resetFields()
            // this.$set(this.addform, 'content', "")
            // this.$set(this.addform, 'remark', undefined)
            // this.$set(this.addform, 'link', undefined)
            // this.$set(this.addform, 'title', undefined)
            // this.$set(this.addform, 'image', '')
            // this.addform.content = ''
            // this.form.remark = ''
            // this.form.link = undefined
            // this.form.title = undefined
            // this.form.image = undefined
          }else{
            this.$message.warning(response.message)
          }
        } else {
           this.$message.error("请填写完整")
        }
       })
    },
    // 修改后的提交按钮
    async editSubmit() {
      this.addform.content = this.html;
      this.$refs.addform.validate(async valid => { // 表单校验
        if (valid) {
          // console.log(this.addform)
          const response = await putSurvey(this.addform)
          if (response.code === 0) {
            this.$message.success('修改成功')
            this.newlyVisible = false;
            this.getOfficaWebSurvey()
            // 重置表单
            this.html = '';
            this.$refs.addform.resetFields()
            // this.$set(this.addform, 'content', "")
            // this.$set(this.addform, 'remark', undefined)
            // this.$set(this.addform, 'link', undefined)
            // this.$set(this.addform, 'title', undefined)
            // this.$set(this.addform, 'image', '')
            // this.addform.content = ''
            // this.form.remark = ''
            // this.form.link = undefined
            // this.form.title = undefined
            // this.form.image = undefined
          }else{
            this.$message.warning(response.message)
          }
        } else {
           this.$message.error("请填写完整")
        }
       })
    },

    // 点击修改
    async lookBtn(item) {
      // this.changeId = item.id
      this.newlyVisible = true;
      this.btnStatus = "修改"
      // this.getIndex(this.index)
      // this.getVideoIndex(this.VideoIndex)
      // // 查询数据然后绑定上去
      this.html = '';
      this.onCreated();
      const response = await getSurveyDetails(item.id)
      if (response.code === 0) {
        this.addform = JSON.parse(JSON.stringify(response.data))  //content和备注
        setTimeout(() => {
          this.html = response.data.content;
        }, 100)
      } else {
        this.$message.warning(response.message)
      }
    },

    // 删除
    async delBtn(id){
      const response = await delSurvey(id)
      if (response.code == 0) {
        this.getOfficaWebSurvey()
        this.$message.success('删除成功')
      } else {
        this.$message.warning(response.message)
      }
    },
    // 重置
    returnBtn() {
      this.year =  moment(current_date()).format('YYYY')
      this.searchName = ''  //输入框
      this.pageSize = 10  //行数
      this.page_no = 1
      this.officlaState = ""  //状态
      this.getOfficaWebSurvey()
    },

  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  }
}
</script>

<style lang="scss" scoped>
.datePicker{
  background-color: #fff;
  width: 100%;
  height: 64px;
  padding: 20px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  position: relative;
  justify-content: space-between;

  .liveMeet-top-input{
    display: flex;
    align-items: center;
  }
  .date{
    display: flex;
    align-items: center;
    // align-items: center;
  }

  .status{
    float: right;
    position: absolute;
    right: 50px;
  }
}

.uploadImg{
  width: 100%;
  border: #DDDDDD 2px dashed;
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/add.png");
  background-size:100% 100%;
  background-repeat:no-repeat;
  cursor: pointer;
  margin-right: 11px;

  /* 需要撤回就删 */
  float: left;
  display: inline-block;
}

.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 210px;
  height: 110px;
  margin-left: 5px;
  padding: 5px;
}

.multi-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}

</style>
